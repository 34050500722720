import style from "./Footer.module.scss"
import fig from "../../assets/png/figure_6.png";

export const Footer = () => {
    return (
        <footer className={style.footer}>
            <p>
                hO0lk wiv hat 10oX naw cum
            </p>
            <img src={fig} alt=""/>
        </footer>
    )
}