import style from "./Toookanamix.module.scss"
import fig5 from "../../assets/png/figure_5.png";

export const Toookanamix = () => {
    return (
        <div className={style.toookanamix}
             id="toookanamix"
        >
           <p className={style.title}>
               toookanamix
           </p>
            <p className={style.description}>
                h0olk wer glaziz i smurt naw
            </p>
            <img src={fig5} alt=""/>
            <p className={style.count}>1 beeliyan tookans</p>
        </div>
    )
}