import style from "./Roadmap.module.scss"

export const Roadmap = () => {
    return (
        <div className={style.roadmap}
             id="roadmap"
        >
            <p className={style.title}>
                roadmap
            </p>
            <p className={style.description}>
                smash orl avangarz gurlfrenz xcept pepa she iz singul muva sadface :(
            </p>
        </div>
    )
}