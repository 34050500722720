import {HashLink} from "react-router-hash-link";
import React from "react";
import style from "./Header.module.scss";
import linkSrc from "../../assets/png/link.png"
import twitterIcon from "../../assets/png/twitter.png";
import tgIcon from "../../assets/png/telegram.png";


export const Header = () => {
    return (
        <header className={style.header}>

            <div className={style.topRow}>


                <HashLink smooth
                          to="/#toookanamix"
                          className={style.link}
                >
                    <p className={style.linkLabel}>toookanamix</p>
                </HashLink>

                <HashLink smooth
                          to="/#roadmap"
                          className={style.link}
                >
                    <p className={style.linkLabel}>roadmap</p>
                </HashLink>

                <a className={style.ticketLink}
                   href="https://Hoolk.io"
                   target="_blank"
                   rel="noreferrer nofollow noopener"
                >
                    <p className={style.linkLabel}>buy $hoolk</p>
                    <img src={linkSrc} alt=""/>
                </a>
            </div>

            <div className={style.middleRow}>
                <p className={style.logo}>$H0olk</p>

                <a href="https://twitter.com/hoolksol"
                   target="_blank"
                   rel="noreferrer nofollow noopener"
                >
                    <img src={twitterIcon} alt=""/>
                </a>

                <a href="http://t.me/HoolkPortal"
                   target="_blank"
                   rel="noreferrer nofollow noopener"
                >
                    <img src={tgIcon} alt=""/>
                </a>
            </div>

            <div className={style.bottomRow}>
                <p>Hoolk iz stronk</p>
                <p>BUY HOLD TEL ZEE REYTARED FRENND ABOOT HULK AUTIZTIC CUZIN</p>
            </div>

        </header>
    )
}