import style from "./Figures.module.scss";
import fig0 from "../../assets/png/figure_0.png";
import fig1 from "../../assets/png/figure_1.png";
import fig2 from "../../assets/png/figure_2.png";
import fig3 from "../../assets/png/figure_3.png";
import fig4 from "../../assets/png/figure_4.png";

export const Figures = () => {
    return (
        <div className={style.figures}>

            <div className={style.block0}>
                <p>HOOLK SMASH Mj IN ZE BUTTOLE</p>
                <img src={fig0} alt=""/>
            </div>

            <div className={style.block1}>
                <img src={fig1} alt=""/>
                <p>MERYJONE LUV BUTT STUFF</p>
            </div>

            <div className={style.blocks}>
                <div className={style.block}>
                    <p>holk y u do dis</p>
                    <img src={fig2} alt=""/>
                </div>
                <div className={style.block}>
                    <p>holk SMASH WIT PATECTION</p>
                    <img src={fig3} alt=""/>
                </div>
            </div>

            <div className={style.bottomBlock}>
                <img src={fig4} alt=""/>
                <p>0kay jus uze patection</p>

            </div>

        </div>
    )
}