import React from 'react';
import style from './App.module.scss';
import { Header } from '../A1_Header/Header';
import { Figures } from '../B0_Figures/Figures';
import { Toookanamix } from '../B1_Toookanamix/Toookanamix';
import { Roadmap } from '../B2_Roadmap/Roadmap';
import { Footer } from '../A2_Footer/Footer';
import BgAudio from '../BgAudio';

export const App = () => {
  return (
    <div className={style.app}>
      <div className={style.inner}>
        <BgAudio />

        <Header />

        <p className={style.address}>
          token address: Cb85u66JqUThurhWnm5pkhmw3Y58zobu5Mf3CbAnq8RV
        </p>

        <Figures />
        <Toookanamix />
        <Roadmap />
        <Footer />

        <p className={style.logo}>hoolk@hoolk.io</p>
      </div>
    </div>
  );
};
