import { useEffect, useRef } from 'react';
import style from './BgAudio.module.scss';
import audio from './assets/music.mp3';

const BgAudio = () => {
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    window.addEventListener('click', () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
    });
  }, []);

  return (
    <div className={style.audio}>
      <audio ref={audioRef} src={audio}></audio>
    </div>
  );
};

export default BgAudio;
